import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  baseUrl,
  checkInFromHotel,
  getCashierData,
  getCurrentUser,
  getEmptyRoomNumber,
  getRoomInformationData,
  getRoomRatePlan,
} from "../../api-config/config";
import { sendEmail } from "../../utils/emailjs-utils";
import {
  formateDateInApiSupport,
  isEqualOrAfter,
} from "../../utils/formatTime";
import moment from "moment";
import { insertPaymentData } from "../../api-config/payment.api";
import {
  getCorporateData,
  getTravelAgentData,
} from "../../api-config/corporate.api";
import { getRoomPrice } from "../../utils/utilFuncForEditReservation";
import DateRangeComponent from "../DateRangePicker";
import { calNoOfNights } from "../../utils/formatNumber";

const ReservationFrom = (props: any) => {
  const {
    calendarInfo,
    selectedRoomInfo,
    handleClose,
    onFormSubmitted,
    roomTypeList1,
    groupIndBtn,
  } = props;

  const currentDate = new Date(); // Get the current date and time
  const nextDayDate = new Date(currentDate);
  nextDayDate.setDate(currentDate.getDate() + 1); // Add one day to get the next day's date

  const [range, setRange] = useState({
    startDate: new Date(calendarInfo.startStr),
    endDate: new Date(calendarInfo.endStr),
    key: "selection",
  });
  const [indRatePlan, setIndRatePlan] = useState(0);

  const defaultCalculatedPrice = {
    total_sale_price: selectedRoomInfo.price * calendarInfo?.noOfNights,
    taxInfo: {},
    total_tax:
      selectedRoomInfo.price +
        (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
          .service_charge /
          100) *
          selectedRoomInfo.price <=
      7500
        ? selectedRoomInfo.price +
          (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
            .service_charge /
            100) *
            selectedRoomInfo.price *
            0.12
        : selectedRoomInfo.price +
          (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
            .service_charge /
            100) *
            selectedRoomInfo.price *
            0.18,
    total_price_with_tax:
      selectedRoomInfo.price +
        (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
          .service_charge /
          100) *
          selectedRoomInfo.price <=
      7500
        ? selectedRoomInfo.price +
          (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
            .service_charge /
            100) *
            selectedRoomInfo.price *
            1.12
        : selectedRoomInfo.price +
          (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
            .service_charge /
            100) *
            selectedRoomInfo.price *
            1.18,
    discountAmount: 0,
    room_service_charge:
      (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
        .service_charge /
        100) *
      selectedRoomInfo.price,
    service_charge_in_percentage: getRoomPrice(
      selectedRoomInfo?.room_type_id,
      roomTypeList1
    ).service_charge,
    total_without_tax:
      selectedRoomInfo.price +
      (getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
        .service_charge /
        100) *
        selectedRoomInfo.price,
    total_without_service: selectedRoomInfo.price,
  };

  const [calculatedPrice, setCalcualtedPrice] = useState(
    defaultCalculatedPrice
  );
  const [originalPrice, setOriginalPrice] = useState(
    selectedRoomInfo.price * calendarInfo.noOfNights || 0
  );

  const [discountValue, setDiscountValue] = useState(0);
  const [discountType, setDiscountType] = useState("Percentage");
  const [mode, setMode] = useState("");
  const [marketSegment, setMarketSegment] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [roomRatePlan, setRoomRatePlan] = useState([]);
  const [noOfAdult, setNoOfAdult] = useState(1);
  const [isReserveButtonClicked, setIsReserveButtonClicked] = useState(false);
  const [roomInfoBasedOnAdult, setRoomInfoBasedOnAdult] = useState({} as any);
  const [noOfChild, setNoOfChild] = useState(0);
  const [rateType, setRateType] = useState("");
  const [selectedRoomPlan, setSelectedRoomPlan] = useState({} as any);
  const [noOfRoomsAvl, setNoOfRoomsAvl] = useState(1);
  const [noOfRoomsSelected, setNoOfRoomsSelected] = useState(1);

  const [data, setData] = useState([]);
  const loggedUser = getCurrentUser();
  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked, "this is checked");

    setChecked(event.target.checked);
  };

  const fetchSegmentData = (e: any) => {
    setMarketSegment(e.target.value);
    switch (e.target.value) {
      case "Corporate":
        return getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
          setData(res.data)
        );
      case "Travel Agent":
        return getTravelAgentData(loggedUser?.hotel_code, 0).then((res) =>
          setData(res.data)
        );
      default:
        return;
    }
  };

  const defaultValues = {
    adult: 1,
    child: 0,
    partialPayment: 0,
    email: "",
    price: "",
    mobNumber: "",
    market_segment: "",
    travel_agent_id: undefined,
    corporate_id: undefined,
    discountMethod: "",
    discountValue: 0,
    firstName: "",
    lastName: "",
    firstName2: "",
    firstName3: "",
    lastName2: "",
    lastName3: "",
    rooms: 1,
    rateType: "",
    payment_mode: "",
    coveredServices: "",
  };

  const formValidation = Yup.object().shape({
    firstName: Yup.string().required("Please enter first name"),
    // firstName2: noOfAdult? Yup.string().required("Please enter first name") : Yup.string(),
    // firstName3: noOfAdult? Yup.string().required("Please enter first name") : Yup.string(),
    mobNumber: Yup.string().required("Please enter your mobile number"),
    email: Yup.string().required("Please enter your email"),

    // coveredServices:
    //   paymentMode === "Bill to company"
    //     ? Yup.string().required("Please Enter Billing Instructions")
    //     : "",
    // rateType: Yup.string().required("Please select a Rate Type"),
    // room_type: Yup.string().required("Please enter room type"),
    // // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
    // short_name: Yup.string().required("Please enter Short name"),
    // // image: Yup.string().required("Please enter City"),
    // adult: Yup.number().required("Please enter Base Occupancy"),
    // child: Yup.string().required("Please enter State"),
  });
  function handleReserveSubmit(e: any) {
    setIsReserveButtonClicked(true);
  }

  const handleSubmit = (formData: any) => {
    formData.adult = noOfAdult;
    formData.child = noOfChild;

    formData.price = calculatedPrice.total_price_with_tax;
    console.log(formData, "final body");
    const other_members = [];
    if (noOfAdult >= 2) {
      if (formData.firstName2) {
        other_members.push({
          first_name: formData.firstName2,
          last_name: formData.lastName2,
        });
      }
      if (formData.firstName3) {
        other_members.push({
          first_name: formData.firstName3,
          last_name: formData.lastName3,
        });
      }
    }
    console.log("before body testing temp========");

    const body = {
      hotel_code: selectedRoomInfo?.hotel_code,
      from_date: range.startDate,
      to_date: range.endDate,
      total_sale_amount: calculatedPrice.total_sale_price,
      total_without_tax: calculatedPrice.total_without_tax,
      tax_amount: calculatedPrice.total_tax,
      total_services_amount: calculatedPrice.room_service_charge,
      service_charge: calculatedPrice.service_charge_in_percentage,
      gross_amount: formData.price,
      discount_value: formData.discountAmount,
      travel_agent_id: formData.travel_agent_id,
      corporate_id: formData.corporate_id,
      discount_type: formData.discountMethod,
      discount_amount: calculatedPrice?.discountAmount || 0,
      minimum_advance: 0,
      payment_mode: paymentMode,
      no_of_nights: calNoOfNights(range.startDate, range.endDate),
      paid_amount: formData.partialPayment,
      balance_amount: formData.price - formData.partialPayment,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      market_segment: formData.market_segment,
      address: "",
      phone_number: formData.mobNumber,
      mobile_number: formData.mobNumber,
      country: "NL",
      booking_status: isReserveButtonClicked ? "pending" : "check_in",
      check_in_time: isReserveButtonClicked
        ? null
        : moment().format("YYYY-MM-DD HH:mm:ss"),
      room_type_id: selectedRoomInfo.room_type_id,
      rate_plan_id: selectedRoomPlan.id,
      rate_plan_amount: selectedRoomPlan.rate_amount || 0,
      booking_type: groupIndBtn.btnType,
      no_of_rooms: noOfRoomsSelected,
      covered_services: formData.coveredServices,

      room_assinged_adult_info: [
        {
          no_of_adults: formData.adult,
          no_of_child: formData.child || 0,
          no_of_rooms: +formData.rooms,
          room_id: selectedRoomInfo.id,
        },
      ],
      room_booking: [
        {
          room_id: selectedRoomInfo.id,
          no_of_pax: Number(formData.adult + formData.child) || formData.adult,
          no_of_adults: formData.adult,
          no_of_children: formData.child || 0,
          no_of_rooms: +formData.rooms,
          total_sale_amount: calculatedPrice.total_sale_price,
          discount_amount: calculatedPrice?.discountAmount || 0,
          coupon_amount: 0,
          total_without_tax: calculatedPrice.total_without_tax,
          tax_amount: calculatedPrice.total_tax,
          gross_amount: formData.price,
        },
      ],
      booking_service: [],
      other_members,
    };
    setIsReserveButtonClicked(false);
    console.log("calling reserve button");

    checkInFromHotel(body).then((response: any) => {
      getCashierData({
        hotel_code: loggedUser?.hotel_code,
        date: moment().format("YYYY-MM-DD"),
      }).then((res: any) => {
        const body = {
          booking_id: response.data?.[0],
          paid_amount: formData.partialPayment,
          gross_amount: formData.price,
        } as any;
        if (formData.partialPayment && mode) {
          if (mode === "UPI") {
            body.upi = formData.partialPayment;
          } else if (mode === "CASH") {
            body.cash = formData.partialPayment;
          } else if (mode === "CARD") {
            body.card = formData.partialPayment;
          } else if (mode === "ONLINE") {
            body.online = formData.partialPayment;
          }
          body.comment = `Recevied payment from ${
            formData.firstName + " " + formData.lastName
          } ${
            calendarInfo?.resource?._resource?.title
          } booking status ${(body.booking_status = isReserveButtonClicked
            ? "Reserved"
            : "Check in")}`;
          const cashierBal = res.data[res.data.length - 1];

          body.available_balance = cashierBal.available_balance;
          // body.room_tile = calendarInfo?.resource?._resource?.title;
          // body.customer_name = formData.firstName + formData.lastName;
          // body.booking_status = isReserveButtonClicked
          //   ? "Reservation"
          //   : "Check in";

          insertPaymentData(body);
        }

        // setIsReserveButtonClicked(false);
      });

      if (response?.data?.room_booking?.length > 0) {
        const payload = {
          first_name: response?.data?.first_name,
          checkinDate: response.data?.from_date,
          checkoutDate: response.data?.to_date,
          roomType: response?.data?.room_booking[0]?.room_title,
          roomNo: response.data?.room_booking[0]?.room_id,
          totalPrice: response.data?.gross_amount,
          receiptLink: `${baseUrl}/booking_pdf_pms?booking_id=${response.data.booking_id}&customer_id=${response.data.customer_id}`,
          from_name: response.data?.hotel_name,
          reply_to: "booking@ratebotai.com",
          to_email: response.data?.email,
        };
        sendEmail(payload);
      }
      console.log(response);
      onFormSubmitted(response);
    });
  };
  function findRoomInfoAndSetPrice(data: any, discountObj?: any) {
    console.log(data, discountObj, "start");

    const priceObj = {} as any;
    let price: any = originalPrice;
    if (!data.occupancy_flag) {
      onNoOfAdultlChange(noOfAdult);
    } else {
      data?.rooms?.forEach((roomInfo: any, key: any) => {
        console.log(roomInfo);
        if (selectedRoomInfo.room_type_id === roomInfo.room_type_id) {
          price = originalPrice;
          priceObj.total_sale_price = price;
          priceObj.service_charge_in_percentage = roomInfo.service_charge;

          let discountAmount = 0;
          if (
            discountObj &&
            discountObj.discountMethod &&
            discountObj.discountValue
          ) {
            if (discountObj.discountMethod === "Percentage") {
              discountAmount = (price * discountObj.discountValue) / 100;
            } else if (discountObj.discountMethod === "Amount") {
              discountAmount = discountObj.discountValue;
            }
            price = price - discountAmount;
            priceObj.discountAmount = discountAmount;
            priceObj.total_without_service = price;
          } else {
            price = price - discountAmount;
            priceObj.discountAmount = discountAmount;
            priceObj.total_without_service = price;
          }
          const room_service_charge = roomInfo.service_charge
            ? (price * roomInfo.service_charge) / 100
            : 0;
          price = price + room_service_charge;
          priceObj.room_service_charge = room_service_charge;
          priceObj.total_without_tax = price;

          // roomInfo.tax_info.forEach((taxInfoObj: any, key: any) => {
          //   if (
          //     price / calNoOfNights(range.startDate, range.endDate) >=
          //       taxInfoObj.min_amount &&
          //     price / calNoOfNights(range.startDate, range.endDate) <=
          //       taxInfoObj.max_amount
          //   ) {
          //     let tax = 0;
          //     tax = (price * taxInfoObj.tax_value) / 100;
          //     priceObj.total_tax = tax;
          //     price = price + tax;
          //     priceObj.total_price_with_tax = price;
          //   } else {
          //     let tax = 0;
          //     tax = (price * 18) / 100;
          //     priceObj.total_tax = tax;
          //     price = price + tax;
          //     priceObj.total_price_with_tax = price;
          //   }
          // });
          if (price / calendarInfo.noOfNights > 7500) {
            let tax = 0;
            tax = (price * 18) / 100;
            priceObj.total_tax = tax;
            price = price + tax;
            priceObj.total_price_with_tax = price;
          } else {
            let tax = 0;
            tax = (price * 12) / 100;
            priceObj.total_tax = tax;
            price = price + tax;
            priceObj.total_price_with_tax = price;
          }
        }
      });

      setCalcualtedPrice({ ...calculatedPrice, ...priceObj });
    }
  }

  function onSetRange(value: any) {
    setRange(value);
  }
  const handleRoomChanged = (e: any) => {
    setNoOfRoomsSelected(+e.target.value);
  };
  useEffect(() => {
    // ------getting empty Rooms ---------------------------
    const body = {
      from_date: formateDateInApiSupport(range.startDate),
      to_date: formateDateInApiSupport(range.endDate),
      pms_hotel_code: loggedUser?.hotel_code,
      room_type_id: selectedRoomInfo.room_type_id,
    };

    getEmptyRoomNumber(body)
      .then((response: any) => {
        setNoOfRoomsSelected(1);
        setNoOfRoomsAvl(response.data.length);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
      });
    // -------------------end of getting empty rooms
    // console.log(range.startDate, range.endDate, "effect comming room calender");
    setRateType("");
    setCalcualtedPrice((prev: any) => {
      return {
        ...prev,
        service_charge_in_percentage:
          +getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1)
            .service_charge || 10,
      };
    });
    setOriginalPrice(
      getRoomPrice(selectedRoomInfo?.room_type_id, roomTypeList1).price *
        calNoOfNights(range.startDate, range.endDate)
    );
  }, [range.startDate, range.endDate, selectedRoomInfo?.room_type_id]);

  useEffect(() => {
    getRoomRatePlan({
      hotel_code: selectedRoomInfo?.hotel_code,
      room_type_id: selectedRoomInfo?.room_type_id,
    }).then((response) => setRoomRatePlan(response?.data));
  }, []);

  useEffect(() => {
    findRoomInfoAndSetPrice(roomInfoBasedOnAdult, {
      discountMethod: discountType,
      discountValue: Number(discountValue),
    });
  }, [originalPrice, discountValue]);

  const handleOnchangePlan = (id: any) => {
    // console.log(id, "onchange handle function plan blah blajh");
    // const match: any = roomRatePlan?.find((plan: any) => plan?.name === id);
    // console.log(match, "comming frommactched");
    // setSelectedRoomPlan(match);
    // setOriginalPrice((prev: any) => prev + match.rate_amount);
    const match: any = roomRatePlan.find((plan: any) => plan.name === id);

    if (match) {
      const rateAmount = match.rate_amount;
      roomInfoBasedOnAdult?.rooms?.forEach((singleRoomInfo: any) => {
        if (selectedRoomInfo.room_type_id === singleRoomInfo?.room_type_id) {
          // setOriginalPrice(
          //   singleRoomInfo?.room_price?.total_price +
          //     rateAmount *
          //       noOfAdult *
          //       calNoOfNights(range.startDate, range.endDate)
          // );
          setOriginalPrice(
            (prev) =>
              prev +
              rateAmount *
                noOfAdult *
                calNoOfNights(range.startDate, range.endDate)
          );
        }
      });
      setSelectedRoomPlan(match);
      // setOriginalPrice(
      //   selectedRoomInfo.price * calendarInfo.noOfNights + (rateAmount *noOfAdult)
      // );
    }
    // setRateType(id)
  };
  function onNoOfAdultlChange(value: any, str: any = "") {
    let child = 0;
    setRateType("");
    if (str === "child") {
      child = Number(value);
      console.log("child -----", child);

      setNoOfChild(child);
    } else {
      value = Number(value);
      setNoOfAdult(value);
    }
    const currentDate = new Date(range.endDate);
    const nextDay = new Date(currentDate);
    if (calNoOfNights(range.startDate, range.endDate) === 1) {
      nextDay.setDate(currentDate.getDate());
    } else {
      nextDay.setDate(currentDate.getDate() + 1);
    }
    const payload = {
      hotel_code: selectedRoomInfo?.hotel_code,
      start_date: range.startDate,
      end_date: range.endDate,
      room_info: [
        {
          number_of_adults: +value,
          number_of_children: child,
          number_of_children_0_6: 0,
          number_of_children_6_12: 0,
        },
      ],
    };

    getRoomInformationData(payload).then((roomInformationData: any) => {
      setRoomInfoBasedOnAdult(roomInformationData.data);
      findRoomInfoAndSetPrice(roomInformationData.data);

      roomInformationData?.data?.rooms?.forEach((singleRoomInfo: any) => {
        if (selectedRoomInfo.room_type_id === singleRoomInfo?.room_type_id) {
          setOriginalPrice(singleRoomInfo?.room_price?.total_price);
        }
      });
    });
  }
  const elements: any = [];
  for (let i = 2; i <= noOfAdult; i++) {
    elements.push(
      <>
        <FormGroup>
          <Field
            name={`firstName${i}`}
            className="con-field p-1 rounded border focus:ring focus:border-blue-500"
            placeholder={`Enter First Name (extra ${i})`}
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name={`firstName${i}`} />
          </p>
        </FormGroup>
        <FormGroup>
          <Field
            name={`lastName${i}`}
            className="con-field p-1 rounded border focus:ring focus:border-blue-500"
            placeholder={`Enter Last Name (extra ${i})`}
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name={`lastName${i}`} />
          </p>
        </FormGroup>
      </>
    );
  }

  if (calendarInfo) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Align items vertically
            padding: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            fontFamily: "Arial, sans-serif", // Change the font family
            height: "80px", // Reduce the height of the container
          }}
        >
          <div style={{ flex: 1 }}>
            <p>Duration:</p>
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={false}
              startFalse={true}
            />
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <p style={{ marginBottom: 0 }}>
              Room Type:{" "}
              <span style={{ color: "blue", fontWeight: "bold" }}>
                {selectedRoomInfo?.room_type_name}
              </span>
            </p>
          </div>
        </div>

        <Formik
          initialValues={defaultValues}
          validationSchema={formValidation}
          onSubmit={(event) => handleSubmit(event)}
        >
          {({ values }) => (
            <Form className="grid gap-4 lg:grid-cols-1 p-8">
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <div className="form-group">
                  <label htmlFor="adult" className="pb-2 font-semibold">
                    Adult
                  </label>
                  <div className="relative">
                    <select
                      name="adult"
                      onChange={(e) => onNoOfAdultlChange(e.target.value)}
                      value={noOfAdult}
                      className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none transition-transform transform-gpu hover:scale-105"
                    >
                      <option value="">Select</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </select>
                    <p className="text-red-600">
                      <ErrorMessage name="adult" />
                    </p>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700 transition-transform transform-gpu">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="child" className="pb-2 font-semibold">
                    Child
                  </label>
                  <div className="relative">
                    <select
                      name="child"
                      onChange={(e) =>
                        onNoOfAdultlChange(e.target.value, "child")
                      }
                      value={noOfChild}
                      className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none"
                    >
                      <option value="0">0</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </select>
                    <p className="text-red-600">
                      <ErrorMessage name="child" />
                    </p>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="rooms" className="pb-2 font-semibold">
                    Rooms
                  </label>
                  <div className="relative">
                    <select
                      name="rooms"
                      className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none"
                      value={noOfRoomsSelected}
                      onChange={handleRoomChanged}
                    >
                      {Array.from({ length: noOfRoomsAvl }, (_, i) => (
                        <option key={i} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-600">
                      <ErrorMessage name="rooms" />
                    </p>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 p-2">
                <FormGroup>
                  <Field
                    name="firstName"
                    className="con-field p-1 rounded border focus:ring focus:border-blue-500"
                    placeholder="Enter First Name"
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="firstName" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="lastName"
                    className="con-field p-1 rounded border focus:ring focus:border-blue-500"
                    placeholder="Enter Last Name"
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="lastName" />
                  </p>
                </FormGroup>
              </div>

              {/* <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 p-2 ">
                {elements}
              </div> */}
              <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 p-2 ">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Email
                  </label>
                  <Field
                    name="email"
                    className="con-field"
                    placeholder="Enter Email id"
                    id="email"
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="email" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Mobile
                  </label>
                  <Field
                    name="mobNumber"
                    className="con-field"
                    placeholder="Enter Mobile no*"
                    id="mobNumber"
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="mobNumber" />
                  </p>
                </FormGroup>
              </div>
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Market Segment
                  </label>
                  <Field
                    as="select"
                    name="market_segment"
                    value={marketSegment}
                    onChange={fetchSegmentData}
                  >
                    <option value="">Select</option>
                    <option value="Corporate">Corporate</option>
                    <option value={"Travel Agent"}>Travel Agent</option>
                    <option value={"Walk Inn"}>Walk Inn</option>
                    <option value={"Wedding"}>Wedding</option>
                    <option value={"Events"}>Events</option>
                    <option value={"Leisure"}>Leisure</option>
                  </Field>

                  <p className="text-red-600">
                    <ErrorMessage name="Type" />
                  </p>
                </FormGroup>
                {marketSegment === "Corporate" ? (
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Corporate
                    </label>
                    <Field as="select" name="corporate_id">
                      <option value="">Select</option>
                      {data.length > 0 &&
                        data.map((item: any) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.corporate_name}
                          </option>
                        ))}
                    </Field>
                  </FormGroup>
                ) : marketSegment === "Travel Agent" ? (
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Travel Agent
                    </label>
                    <Field as="select" name="travel_agent_id">
                      <option value="">Select</option>
                      {data.length > 0 &&
                        data.map((item: any) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.billing_company_name}
                          </option>
                        ))}
                    </Field>
                  </FormGroup>
                ) : null}
              </div>
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Price{" "}
                    <span style={{ fontSize: "13px" }}>
                      (
                      {calNoOfNights(range.startDate, range.endDate) > 1
                        ? `${calNoOfNights(
                            range.startDate,
                            range.endDate
                          )} nights`
                        : `${calNoOfNights(
                            range.startDate,
                            range.endDate
                          )} night`}
                      )
                    </span>
                  </label>
                  <Field
                    name="price_1"
                    className="con-field"
                    placeholder="Enter Base Price*"
                    id="price_1"
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setOriginalPrice(+e.target.value);
                    }}
                    value={originalPrice}
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    sx={{ fontSize: 12, width: "fit-content" }}
                    control={
                      <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Price Inc Tax"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Rate Type
                  </label>
                  <Field
                    as="select"
                    name="rateType"
                    onChange={(e: any) => {
                      handleOnchangePlan(e.target.value);
                      setRateType(e.target.value);
                    }}
                    value={rateType}
                  >
                    <option value={""}>Select</option>
                    {roomRatePlan?.map((plan: any) => (
                      <option value={plan?.name} key={plan?.id}>
                        {plan?.name}
                      </option>
                    ))}
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="rateType" />
                  </p>
                </FormGroup>
              </div>
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Discount Method
                  </label>
                  <Field as="select" name="discountMethod">
                    <option value="">Select</option>
                    <option value={"Percentage"}>Percentage</option>
                    <option value={"Amount"}>Amount</option>
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="adult" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Percentage/Amount
                  </label>
                  <Field
                    name="discountValue"
                    className="con-field"
                    placeholder="Enter Percentage/Amount if any"
                    id="partialPayment"
                    type="number"
                    onBlur={(e: any) => {
                      setDiscountValue(e.target.value);
                      setDiscountType(values.discountMethod);
                    }}
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="partialPayment" />
                  </p>
                </FormGroup>
              </div>
              {/* <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Payment Method
                  </label>
                  <Field
                    as="select"
                    onChange={(e: any) => setMode(e.target.value)}
                    name="partialPaymentMethod"
                  >
                    <option value="">Select</option>
                    <option value={"UPI"}>UPI</option>
                    <option value={"CASH"}>CASH</option>
                    <option value={"CARD"}>CARD</option>
                    <option value={"ONLINE"}>ONLINE</option>
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="adult" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Partial Payment
                  </label>
                  <Field
                    disabled={mode === "" ? true : false}
                    name="partialPayment"
                    className="con-field"
                    placeholder="Enter Partial payment if any"
                    id="partialPayment"
                    type="number"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="partialPayment" />
                  </p>
                </FormGroup>
              </div> */}
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Final Price{" "}
                    <span style={{ fontWeight: "normal", fontSize: "10px" }}>
                      (excl. tax & svc. charge)
                    </span>
                  </label>
                  <Field
                    name="price"
                    className="con-field"
                    placeholder="Enter Base Price*"
                    id="price"
                    disabled={true}
                    value={
                      checked
                        ? calculatedPrice.total_price_with_tax
                        : calculatedPrice.total_without_service
                    }
                    type="number"
                  />
                </FormGroup>

                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Payment Mode
                  </label>
                  <Field
                    as="select"
                    name="payment_mode"
                    value={paymentMode}
                    onChange={(e: any) => setPaymentMode(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Direct">Direct</option>
                    <option value={"Bill to company"}>Bill to company</option>
                  </Field>

                  <p className="text-red-600">
                    <ErrorMessage name="Type" />
                  </p>
                </FormGroup>
              </div>
              {paymentMode === "Bill to company" ? (
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Billing Instructions
                  </label>
                  <Field
                    name="coveredServices"
                    className="con-field p-1 rounded border focus:ring focus:border-blue-500"
                    placeholder="Enter Billing Instructions."
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="coveredServices" />
                  </p>
                </FormGroup>
              ) : null}
              {/* <FormGroup>
                <label htmlFor="" className="pb-2">
                  Business source
                </label>
                <Field
                  as="select"
                  name="market_segment"
                  value={marketSegment}
                  onChange={fetchSegmentData}
                >
                  <option value="">Select</option>
                  <option value="Corporate">Corporate</option>
                  <option value={"Travel Agent"}>Travel Agent</option>
                  <option value={"Walk Inn"}>Walk Inn</option>
                  <option value={"Wedding"}>Wedding</option>
                  <option value={"Events"}>Events</option>
                  <option value={"Leisure"}>Leisure</option>
                </Field>

                <p className="text-red-600">
                  <ErrorMessage name="Type" />
                </p>
              </FormGroup> */}

              <div className="grid gap-4 lg:grid-cols-3">
                <FormGroup>
                  <Button
                    disabled
                    type="submit"
                    name="tempreserve"
                    className="bg-sky-300 hover:bg-sky-400"
                  >
                    Temp Reserve
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Button
                    type="submit"
                    onClick={(e) => handleReserveSubmit(e)}
                    name="reserve"
                    className="bg-sky-300 hover:bg-sky-400"
                  >
                    Reserve
                  </Button>
                </FormGroup>
                {isEqualOrAfter(moment().startOf("day"), calendarInfo.start) ? (
                  <FormGroup>
                    <Button
                      type="submit"
                      name="checkIn"
                      className="bg-sky-300 hover:bg-sky-400"
                    >
                      Check In
                    </Button>
                  </FormGroup>
                ) : (
                  <></>
                )}
              </div>
              <div>Agent/Corporate </div>
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    return <p>Sorry something wrong</p>;
  }
};

export default ReservationFrom;