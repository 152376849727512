import axios from "axios";
import api from "./axiosInstance";
import { redirect } from "react-router-dom";

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const hotelLogin = (
  mobile_number: string,
  email_id: string,
  hotel_code: number,
  password: string
) => {
  return api
    .post(baseUrl + "/hotel_login", {
      mobile_number,
      email_id,
      hotel_code,
      password,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getHotelDetails = (hotel_code: number) => {
  return api
    .post(baseUrl + "/get_hotels_details_pms", {
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const updateHotelDetails = (payload: any) => {
  return api
    .post(baseUrl + "/update_hotel_details", payload)
    .then((response: any) => {
      return response.data;
    });
};

export const hotelUserRegisteration = (
  first_name: string,
  last_name: string,
  hotel_name: string,
  mobile_number: string,
  email_id: string,
  password: string,
  country: string,
  state: string,
  no_of_rooms: number
) => {
  return api
    .post(baseUrl + "/register_hotels_for_pms_software", {
      first_name,
      last_name,
      hotel_name,
      mobile_number,
      email_id,
      password,
      country,
      state,
      no_of_rooms,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const login = (user_name: string, password: string) => {
  return api
    .post(baseUrl + "/admin_login", {
      user_name,
      password,
    })
    .then((response: any) => {
      if (response.data.status_message === "success") {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

export const Adminregister = (
  firstname: string,
  lastname: string,
  email: string,
  password: string,
  username: string,
  phone: string
) => {
  return api
    .post(baseUrl + "/insert_admin_users", {
      firstname,
      lastname,
      email,
      password,
      username,
      phone,
      role: "admin",
      otherneededdetails: "",
      brand_ids: [1, 2, 3],
      hotel_ids: [1, 2, 3],
    })
    .then((response: any) => {
      return response.data;
    });
};

export const logout = async () => {
  const response = await api.post("/logout");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  redirect("/login");
  console.log("this is before reload");
  window.location.reload();
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};

/*** Blocks ***/

export const getBlockData = (hotel_code: number) => {
  return api
    .post(baseUrl + "/get_blocks_data", {
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const insertBlockData = (hotel_code: string, block_name: string) => {
  return api
    .post(baseUrl + "/insert_blocks_data", {
      hotel_code,
      block_name,
    })
    .then((response: any) => {
      return response.data;
    });
};

/*** Floors ***/

export const getFloorData = (block_id: number) => {
  return api
    .post(baseUrl + "/get_floor_data", {
      block_id,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const insertFloorData = (block_id: string, floor_name: string) => {
  return api
    .post(baseUrl + "/insert_floor_data", {
      block_id,
      floor_name,
    })
    .then((response: any) => {
      return response.data;
    });
};

/*** Room Types ***/

export const getRoomType = (hotel_code: number, floor_id?: number) => {
  return api
    .post(baseUrl + "/get_room_type_data", {
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const getRoomRatePlan = (hotel_code: any) => {
  return api
    .post(baseUrl + "/get_room_plans_data_pms", hotel_code)
    .then((response: any) => {
      return response.data;
    });
};

export const deleteRoomRatePlan = (body: any) => {
  return api
    .post(baseUrl + "/delete_room_plans_data_pms", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getEmptyRoomNumber = (body: any) => {
  return api
    .post(baseUrl + "/check_rooms_availability_for_pms", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertBlockRooms = (body: any) => {
  return api
    .post(baseUrl + "/insert_pms_block_room", body)
    .then((response: any) => {
      return response.data;
    });
};
export const routeChargesTo = (body: any) => {
  return api
    .post(baseUrl + "/route_charges_in_prov_inv", body)
    .then((response: any) => {
      return response.data;
    });
};
export const routePaymentTo = (body: any) => {
  return api
    .post(baseUrl + "/route_payments_in_prov_inv", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getChargesAndPayment = (body: any) => {
  return api
    .post(baseUrl + "/get_charges_and_payments_for_prov_inv", body)
    .then((response: any) => {
      return response.data;
    });
};
export const updateBlockRooms = (body: any) => {
  return api
    .post(baseUrl + "/update_pms_block_room", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getRoomInformationData = (body: any) => {
  return api
    .post(baseUrl + "/get_room_information_data", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getBookingInfo = (booking_id: number | string) => {
  return api
    .post(baseUrl + "/get_booking_data_pms", { booking_id: Number(booking_id) })
    .then((response: any) => {
      return response.data;
    });
};

export const checkInFromHotel = (body: any) => {
  return api.post(baseUrl + "/do_booking_pms", body).then((response: any) => {
    return response.data;
  });
};
export const changeCheckingInfo = (body: any) => {
  return api
    .post(baseUrl + "/change_info_for_check_in", body)
    .then((response: any) => {
      return response.data;
    });
};

export const insertRoomType = (
  room_type_name: string,
  base_occupancy: number,
  higher_occupancy: number,
  extra_bed: number,
  no_rooms_stock: number,
  price: number,
  extra_bed_price: number,
  created_by: any,
  hotel_code: any,
  amenities_ids: string[],
  tax_ids: any[],
  service_charge: number
) => {
  return api
    .post(baseUrl + "/insert_room_type_data", {
      room_type_name,
      base_occupancy,
      higher_occupancy,
      extra_bed,
      no_rooms_stock,
      amenities_ids,
      price,
      extra_bed_price,
      tax_ids,
      created_by,
      hotel_code,
      service_charge,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const insertRoomRatePlan = (
  name: string,
  included: string,
  description: string,
  rate_amount: number,
  room_type_id: number,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/insert_room_plan_data_pms", {
      name,
      included,
      description,
      rate_amount,
      room_type_id,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const updateRoomRatePlan = (
  name: string,
  included: string,
  description: string,
  rate_amount: number,
  room_type_id: number,
  hotel_code: number,
  id: number
) => {
  return api
    .post(baseUrl + "/update_room_plan_data_pms", {
      name,
      included,
      description,
      rate_amount,
      room_type_id,
      hotel_code,
      id,
    })
    .then((response: any) => {
      return response.data;
    });
};
/*** Guest Look up ***/

export const getGuestData = (
  partial_name: string,
  email: string,
  name: string,
  guest_id: number,
  mobile_number: number
) => {
  return api
    .post(baseUrl + "/search_guest_data", {
      partial_name,
      email,
      name,
      guest_id,
      mobile_number,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const insertGuestData = (
  partial_name: string,
  email: string,
  first_name: string,
  last_name: string,
  user_type: string,
  gender: string,
  address: string,
  mobile_number: string
) => {
  return api
    .post(baseUrl + "/insert_guest_data", {
      partial_name,
      email,
      first_name,
      last_name,
      user_type,
      gender,
      address,
      mobile_number,
    })
    .then((response: any) => {
      return response.data;
    });
};

/*** Room Operations***/

export const getCheckInOrders = (
  from_date: any,
  to_date: any,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_check_in_orders", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getPmsBlockRoom = (
  from_date: any,
  to_date: any,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_pms_block_room", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getCancelledBill = (
  start_date: string,
  end_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/cancel_bill_report", {
      start_date,
      end_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getCheckOutOrders = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_check_out_orders", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getExpectedDepartureReports = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/expected_departure_report", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const getDiscountReports = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_discount_report", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getAllowanceReports = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_allowance_report", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

// management dashboard api ...
export const availability_analysis_pms = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/availability_analysis_pms", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const bob_analysis_pms = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/bob_analysis_pms", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
// -------------------------------------
export const getReservationOrders = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_reservation_orders", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getCheckOutPendingOrders = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_check_out_pending_orders", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getCancelledOrders = (
  from_date: string,
  to_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_cancelled_orders", {
      from_date,
      to_date,
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const getHouseKeepingList = (hotel_code: number) => {
  return api
    .post(baseUrl + "/get_house_keeping_list", {
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const getRevenueReport = (
  hotel_code: number,
  start_date: string,
  end_date: string
) => {
  return api
    .post(baseUrl + "/get_revenue_report", {
      hotel_code,
      start_date,
      end_date,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const getPaymentReport = async (
  hotel_code: number,
  start_date: string,
  end_date: string
) => {
  return api
    .post(baseUrl + "/get_payment_report", {
      hotel_code,
      start_date,
      end_date,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const insertRoomData = async (body: any) => {
  return api
    .post(baseUrl + "/insert_rooms_data_pms", body)
    .then((response: any) => {
      return response.data;
    });
};
export const updateGuestData = (body: any) => {
  return api
    .post(baseUrl + "/update_guest_data", body)
    .then((response: any) => {
      return response.data;
    });
};
export const updateGuestDataPms = (body: any) => {
  return api
    .post(baseUrl + "/update_guest_data_pms", body)
    .then((response: any) => {
      return response.data;
    });
};

export const insertGuestDataPms = (body: any) => {
  return api
    .post(baseUrl + "/insert_guest_data_pms", body)
    .then((response: any) => {
      return response.data;
    });
};

export const changeGuestDataPms = (body: any) => {
  return api
    .post(baseUrl + "/change_guest_data_pms", body)
    .then((response: any) => {
      return response.data;
    });
};

export const getFeaturesList = async (body: any) => {
  return api
    .post(baseUrl + "/get_room_features_data", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertFeatureData = async (body: any) => {
  return api
    .post(baseUrl + "/insert_room_features_data", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getPaymentData = async (body: any) => {
  return api
    .post(baseUrl + "/get_payment_report", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertPaymentData = async (body: any) => {
  return api
    .post(baseUrl + "/insert_payment_data", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertPaymentsPms = async (body: any) => {
  return api
    .post(baseUrl + "/insert_payments_pms", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertOtherPaymentData = async (body: any) => {
  return api
    .post(baseUrl + "/add_other_payment_in_folio", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertChargesPms = async (body: any) => {
  return api
    .post(baseUrl + "/insert_charges_pms", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getFolioOptionsPms = async (body: any) => {
  return api
    .post(baseUrl + "/get_folio_options_pms", body)
    .then((response: any) => {
      return response.data;
    });
};
export const editChargesPms = async (body: any) => {
  return api.post(baseUrl + "/edit_charges_pms", body).then((response: any) => {
    return response.data;
  });
};

export const genrateProvInvoice = async (body: any) => {
  return api
    .post(baseUrl + "/generate_prov_inv", body)
    .then((response: any) => {
      return response.data;
    });
};
export const invoicePms = async (body: any) => {
  return api
    .get(
      baseUrl +
        `/invoice_pms?inv_no=${body?.inv_no}&hotel_code=${body?.hotel_code}`
    )
    .then((response: any) => {
      return response.data;
    });
};
export const provInvToInv = async (body: any) => {
  return api.post(baseUrl + "/prov_inv_to_inv", body).then((response: any) => {
    return response.data;
  });
};
export const getFolioInfoData = async (body: any) => {
  return api
    .post(baseUrl + "/get_message_and_all_in_folio", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertFolioInfoData = async (body: any) => {
  return api
    .post(baseUrl + "/add_and_edit_message_and_all_in_folio", body)
    .then((response: any) => {
      return response.data;
    });
};
export const insertCashierDaliyPaymentData = async (body: any) => {
  return api
    .post(baseUrl + "/insert_cashier_daliy_payment_data", body)
    .then((response: any) => {
      return response.data;
    });
};
export const addFundsToCashCounter = async (body: any) => {
  return api
    .post(baseUrl + "/add_fund_to_cash_counter", body)
    .then((response: any) => {
      return response.data;
    });
};
export const withdrawFundsFromCashCounter = async (body: any) => {
  return api
    .post(baseUrl + "/withdraw_fund_from_cash_counter", body)
    .then((response: any) => {
      return response.data;
    });
};

export const updateHouseKeepingData = async (body: any) => {
  return api
    .post(baseUrl + "/assigne_house_keeping_to_room", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getNightAuditSummary = async (body: any) => {
  return api
    .post(baseUrl + "/night_audit_summary", body)
    .then((response: any) => {
      return response.data;
    });
};
export const performNightAudit = async (body: any) => {
  return api
    .post(baseUrl + "/perform_night_audit", body)
    .then((response: any) => {
      return response.data;
    });
};

export const getCashierData = async (body: any) => {
  return api
    .post(baseUrl + "/daily_payment_data_for_close_counter", body)
    .then((response: any) => {
      return response.data;
    });
};
// export const getCashierData = async (
//   body: any
// ) => {
//   return api
//     .post(baseUrl + "/get_daliy_payment_data", body)
//     .then((response: any) => {
//       return response.data;
//     });
// };
export const getNightAudit = async (body: any) => {
  return api
    .post(baseUrl + "/get_info_for_perform_night_audit", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getTaxList = async (hotel_code: number) => {
  return api
    .post(baseUrl + "/get_tax_info", { hotel_code })
    .then((response: any) => {
      return response.data;
    });
};
export const insertTaxList = async (body: any) => {
  return api.post(baseUrl + "/insert_tax_data", body).then((response: any) => {
    return response.data;
  });
};
export const deleteTaxList = async (tax_id: number) => {
  return api
    .post(baseUrl + "/delete_tax_data", { tax_id })
    .then((response: any) => {
      return response.data;
    });
};
export const getLastNightAuditDate = async (hotel_code: number) => {
  return api
    .post(baseUrl + "/check_night_audit_and_get_last_date", { hotel_code })
    .then((response: any) => {
      return response.data;
    });
};

export const getManagerFlashReport = async (body: any) => {
  return api
    .post(baseUrl + "/manager_flash_report", body)
    .then((response: any) => {
      return response.data;
    });
};
export const getHistoryAndForecastReport = async (body: any) => {
  return api
    .post(baseUrl + "/history_and_forecast_report", body)
    .then((response: any) => {
      return response.data;
    });
};
